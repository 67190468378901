/* eslint-disable */
<template>
  <div class="banner-container">
    <div class="" @mousewheel="changeBanner(this)">
    </div>
    <div class="top-banner-wrapper">
      <div class="content-wrapper">
        <div>平台企业</div>
        <div>综合质量服务平台</div>
        <div class="sub-desc">基于国家重大研发计划，支持自助式、一站式、推荐式三大服务模式，涵盖8大服务分类，旨在帮助平台企业解决发展道路上的疑难问题</div>
        <el-button type="warning" style="width: 200px">立 即 体 验 <i class="el-icon-right"/></el-button>
      </div>
      <div class="logo"></div>
    </div>
    <!--数量统计模块begin-->
    <div class="part1 animate__animated animate__fadeInUp">
      <div class="wrap uf uf-pa">
        <div class="li uf uf-ac">
          <i class="iconfont icon-chat"></i>
          <div>
            <div class="h fwb">2,341</div>
            <div class="dec">
              日活跃人数
            </div>
          </div>
        </div>
        <div class="li uf uf-ac">
          <i class="iconfont icon-huodong"></i>
          <div>
            <div class="h fwb">3,359</div>
            <div class="dec">
              今日订单量
            </div>
          </div>
        </div>
        <div class="li uf uf-ac">
          <i class="iconfont icon-shafa"></i>
          <div>
            <div class="h fwb">150,010</div>
            <div class="dec">
              平台注册人数
            </div>
          </div>
        </div>
        <div class="li uf uf-ac">
          <i class="iconfont icon-zixun"></i>
          <div>
            <div class="h fwb">41,045</div>
            <div class="dec">
              入驻服务商家数量
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--数量统计模块 end-->
    <div class="p-title uf uf-ac uf-pc">
      <i class="iconfont icon-hengxian" style="transform: rotate(180deg)"></i>
      <span>行业贡献</span>
      <i class="iconfont icon-hengxian"></i>
    </div>
    <div class="content-row">
      <div class="row-img-wrapper">
        <div class="img-container mt-2">
          <img width="80%" src="../../assets/img/gongxian1/1.png"/>
        </div>
      </div>
      <div class="row-desc-wrapper">
        <div class="desc-container">
          <div class="desc-title mt-4">行业贡献1</div>
          <div class="desc-content mt-2">
            在检验检测服务领域，通过“平台在线下单-联动物流运样-专人接样-实验室动态检测-质量专家视频协助”的线上线下协同服务方式，
            积极为平台企业提供免费咨询服务和低收费检测认证服务。质量专家入驻数据安全共享平台，
            在线上提供提供免费的远程技术咨询服务。疫情期间坚持为1000余家平台企业提供低收费检测服务，积极开展线上公益宣讲活动，
            全年共开展10多场线下公益活动，服务1053家企业，服务3999人。
            <el-divider/>
          </div>
        </div>
      </div>
    </div>
    <div class="content-row2">
      <div class="row-desc-wrapper">
        <div class="desc-container">
          <div class="desc-title mt-4">行业贡献2</div>
          <div class="desc-content mt-2">
            中国质量认证中心深入一线，在产品质量提升领域，为企业快速发展量身定制方案。
            华南实验室组织技术专家组走访中山市业务辖区内的大俊童车有限公司、乐美达婴童用品有限公司等多个童车强制性产品认证获证企业，
            对企业在产品质量问题上的安全隐患和相应的质量提升手段等内容提出了针对性的改进意见，为企业“问诊把脉”、答疑解惑。
          </div>
          <el-divider/>
        </div>
      </div>
      <div class="row-img-wrapper">
        <div class="img-container mt-2">
          <img src="../../assets/img/gongxian1/2.png" width="80%">
        </div>
      </div>
    </div>
    <div class="content-row">
      <div class="row-img-wrapper">
        <div class="img-container mt-2">
          <img width="80%" src="../../assets/img/gongxian1/3.png"/>
        </div>
      </div>
      <div class="row-desc-wrapper">
        <div class="desc-container">
          <div class="desc-title mt-4">行业贡献3</div>
          <div class="desc-content mt-2">
            为了进一步帮助平台企业渡过难关，针对小型微型提供了专门的公益性服务或低收费服务，
            公益性服务或低收费服务数量综合占比约35%。累计服务北京市专精特新中小企业470家，专精特新“小巨人”企业35家，
            其中2021年累计服务高精尖领域平台企业将近400家，服务国家级专精特新小巨人企业18家，服务国家高新技术企业253家，
            服务北京市专精特新小巨人企业32家，服务北京市专精特新中小企业21家。
            <el-divider/>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="content-row2">-->
<!--      <p>典型行业4</p>-->
<!--    </div>-->
<!--    <div class="banner-background">-->
<!--      <div class="center">-->
<!--        <div class="left">-->
<!--          <div class="description">-->
<!--            <span>服务型制造公共服务平台</span>-->
<!--            <span>三大服务模式</span>-->
<!--          </div>-->
<!--          <div class="description-detail">-->
<!--            <span>依托项目研究成果，结合系统实际运营需求，设计研发了三大服务模式。用户可根据自身需求情况选择不同的服务类型。</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <div class="item-box1">-->
<!--            <div class="item-logo">-->
<!--            </div>-->
<!--            <div>-->
<!--              <div class="item-desc">-->
<!--                <p>-->
<!--                  自助式服务-->
<!--                </p>-->
<!--                <p class="item-detail">-->
<!--                  自助式服务自助式服务自助式服务自助式服务自助式服务自助式服务自助式服务自助式服务自助式服务-->
<!--                </p>-->
<!--              </div>-->
<!--              <el-divider></el-divider>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="item-box1">-->
<!--            <div class="item-desc">-->
<!--              <p >推荐式服务</p>-->
<!--              <p class="item-detail">推荐式服务推荐式服务推荐式服务推荐式服务推荐式服务推荐式服务</p>-->
<!--              <el-divider></el-divider>-->
<!--            </div>-->
<!--            <div class="item-logo"></div>-->
<!--          </div>-->
<!--          <div class="item-box1">-->
<!--            <div class="item-logo"></div>-->
<!--            <div>-->
<!--              <div class="item-desc">-->
<!--                一站式服务-->
<!--                <p class="item-detail">一站式服务一站式服务一站式服务一站式服务一站式服务一站式服务一站式服务一站式服务一站式服务-->
<!--                  一站式服务</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="part2 animate__animated animate__fadeInUp">-->
<!--      <div class="wrap">-->
<!--        <div class="li-wrap uf fwrap-w">-->
<!--          <div class="li uf">-->
<!--            <i class="iconfont icon-neirong"></i>-->
<!--            <div>-->
<!--              <div class="h">数据分析</div>-->
<!--              <div class="dec mt-2">拥有企业大数据、企业画像、多平台接入，兼容各种复杂的系统架构，高效数据处理分析。</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="li uf">-->
<!--            <i class="iconfont icon-suanfa"></i>-->
<!--            <div>-->
<!--              <div class="h">服务监察</div>-->
<!--              <div class="dec mt-2">从服务需求沟通，到服务制定、下单、服务、回访、评价等全流程监控，再通过运维大数据分析辅助决策，全方位为客户保障服务。</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="li uf">-->
<!--            <i class="iconfont icon-share"></i>-->
<!--            <div>-->
<!--              <div class="h">智能推荐</div>-->
<!--              <div class="dec mt-2">通过企业大数据，以融合知识图谱和协同过滤的混合推荐模型为基础，分析企业个性化需求。</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="li uf">-->
<!--            <i class="iconfont icon-gaoxiao"></i>-->
<!--            <div>-->
<!--              <div class="h">高效运营</div>-->
<!--              <div class="dec mt-2">通过智能化服务体系，帮助企业快速实现服务需求所需、所想、所要，从而加速企业服务决策自动化实现，最大化提高运营效率。</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="part5">
      <div class="wrap">
        <div class="p-title uf uf-ac uf-pc">
          <i class="iconfont icon-hengxian" style="transform: rotate(180deg)"></i>
          <span>产品系列</span>
          <i class="iconfont icon-hengxian"></i>
        </div>
        <div class="li-wrap uf uf-pa">
          <div class="li">
            <div class="li-tit">自助式</div>
            <div class="dec">
              <p>快捷检索服务</p>
              <p>私人订制需求</p>
              <p>急速接单</p>
              <p>全流程跟踪</p>
            </div>
            <a href="/#/index"><el-button type="primary">去使用</el-button></a>
          </div>

          <div class="li">
            <div class="li-tit">推荐式</div>
            <div class="dec">
              <p>汇聚企业特征</p>
              <p>采集企业服务</p>
              <p>分析企业需求</p>
              <p>自动化推荐</p>
            </div>
            <a href="/#/recommend"><el-button type="primary">去使用</el-button></a>
          </div>
          <div class="li">
            <div class="li-tit">一站式</div>
            <div class="dec">
              <p>分析市场动态</p>
              <p>专家级定制</p>
              <p>7*24h专属服</p>
              <p>企业级集成服务</p>
            </div>
            <a href="/#/one-stop"><el-button type="primary">去使用</el-button></a>
          </div>
        </div>
      </div>
    </div>
    <div class="part3 animate__animated animate__fadeInUp">
      <div class="wrap">
        <div class="p-title uf uf-ac uf-pc">
          <i class="iconfont icon-hengxian" style="transform: rotate(180deg)"></i>
          <span>八大服务</span>
          <i class="iconfont icon-hengxian"></i>
        </div>

        <div class="li-wrap uf fwrap-w">
          <div class="li">
            <div class="hover-hide uf uf-ver uf-pc uf-ac">
              <!--<i class="iconfont icon-guanli"></i>-->
              <img src="../../assets/img/index/function_icon01.png" alt="">
              <div class="li-tit">检验检测</div>
            </div>
            <div class="hover-show">
              用指定的方法检验测试某种物体（气体、液体、固体）指定的技术性能指标。适用于各种行业范畴的质量评定，如：土木建筑工程、水利、食品、化学、环境、机械、机器等等。
            </div>
          </div>
          <div class="li">
            <div class="hover-hide uf uf-ver uf-pc uf-ac">
              <!--<i class="iconfont icon-guanli"></i>-->
              <img src="../../assets/img/index/function_icon02.png" alt="">
              <div class="li-tit">标准制定</div>
            </div>
            <div class="hover-show">
              制定一项新标准，是指制定过去没有而现在需要进行制定的标准。它是根据生产发展的需要和科学技术发展的需要及其水平来制定的，因而它反映了当前的生产技术水平。
            </div>
          </div>
          <div class="li">
            <div class="hover-hide uf uf-ver uf-pc uf-ac">
              <!--<i class="iconfont icon-guanli"></i>-->
              <img src="../../assets/img/index/function_icon03.png" alt="">
              <div class="li-tit">质量管理</div>
            </div>
            <div class="hover-show">
              确定质量方针、目标和职责，并通过质量体系中的质量策划、控制、保证和改进来使其实现的全部活动。
            </div>
          </div>
          <div class="li">
            <div class="hover-hide uf uf-ver uf-pc uf-ac">
              <!--<i class="iconfont icon-guanli"></i>-->
              <img src="../../assets/img/index/function_icon04.png" alt="">
              <div class="li-tit">产品安全</div>
            </div>
            <div class="hover-show">
              产品在使用、储运、销售等过程中，保障人体健康和人身、财产安全免受伤害或损失的能力。
            </div>
          </div>
          <div class="li">
            <div class="hover-hide uf uf-ver uf-pc uf-ac">
              <!--<i class="iconfont icon-guanli"></i>-->
              <img src="../../assets/img/index/function_icon05.png" alt="">
              <div class="li-tit">信用评估</div>
            </div>
            <div class="hover-show">
              信用评估机构对征集到的企业信用信息，依据一定指标进行信用等级评定的活动。信用评级作为一个完整的体系，包括信用评级的要素和指标、信用评级的等级和标准、信用评级的方法和模型等方面的内容。
            </div>
          </div>
          <div class="li">
            <div class="hover-hide uf uf-ver uf-pc uf-ac">
              <!--<i class="iconfont icon-guanli"></i>-->
              <img src="../../assets/img/index/function_icon06.png" alt="">
              <div class="li-tit">品牌评价</div>
            </div>
            <div class="hover-show">
              品牌评价的成果与品牌资产评估结果相结合，可以更完整地体现品牌的“内在价值”和“交易价值”，解决了一般只采用无形资产评估方法评估品牌价值的不足之处。
            </div>
          </div>
          <div class="li">
            <div class="hover-hide uf uf-ver uf-pc uf-ac">
              <!--<i class="iconfont icon-guanli"></i>-->
              <img src="../../assets/img/index/function_icon07.png" alt="">
              <div class="li-tit">质量诊断</div>
            </div>
            <div class="hover-show">
              质量诊断，过程或质量管理工作进行诊察，以判定其产品或服务质量是否满足规定要求，或其质量管理工作是否适当、有效、查明发生存在问题的原因，并进而指出改进和提高方向、途径和措施的全部活动。
            </div>
          </div>
          <div class="li">
            <div class="hover-hide uf uf-ver uf-pc uf-ac">
              <!--<i class="iconfont icon-guanli"></i>-->
              <img src="../../assets/img/index/function_icon08.png" alt="">
              <div class="li-tit">工商/产权</div>
            </div>
            <div class="hover-show">
              提供一对一的工商服务和知识产权服务
            </div>
          </div>
        </div>
      </div>
    </div>
    <news-notice></news-notice>
    <page-footer></page-footer>
    <promotion v-if="promotionVisible" ref="promotion"/>
  </div>
</template>

<script>
import NewsNotice from './news-notice'
import PageFooter from '../../components/page-footer'
import Promotion from '../../components/promotion-dialog'
export default {
  components: {
    NewsNotice,
    PageFooter,
    Promotion
  },
  data() {
    return {
      timer: null,
      currentIndex: -1,
      promotionVisible: false
    }
  },
  created() {
    this.sayInterval(-1)
    // this.showPromotion()
    // setTimeout(()=>{
    //   this.promotionVisible = false
    // },2000)
  },
  watch: {

  },
  methods: {
    showPromotion () {
      this.promotionVisible = true
      this.$nextTick(() => {
        this.$refs.promotion.init()
      })
    },
    saySeenter(index){
      this.closeSayInterval()
      this.currentIndex = index
    },
    sayLeave(index){
      this.sayInterval(index)

    },
    //开启定时器
    sayInterval (val){
      let a = val
      let _this = this
      this.timer = setInterval(function () {
        a++
        if(a===4) {
          a=0
        }
        _this.currentIndex = a

      },2000)

    },
    //关闭计时器
    closeSayInterval(){
      clearInterval(this.timer)
    },
    changeBanner: function() {
      if (event.wheelDelta > 0) {
        this.$refs.carousel.prev()
      }
      if (event.wheelDelta < 0) {
        this.$refs.carousel.next()
      }
    },
    toHome() {
      this.$router.push('/home')
    }
  },
}
</script>
<style scoped>
  /*.banner-box >>> .el-carousel__indicators--horizontal {*/
    /*bottom: 60px !important;*/
  /*}*/
  .con {

  }

  .part1 {
    background: #ffffff;
    width: 60%;
    height: 124px;
    position: absolute;
    text-align: center;
    /*margin: 0 auto;*/
    border-radius: 15px;
    top:682px;
    left: 20%;
  }
  .part1 .wrap {
    display: flex;
    justify-content: center;
    /*width: 1300px;*/
    /*margin: 0 auto;*/
  }
  .part1 .li {
    width: 20%;
    position: relative;
    padding: 30px 0;

    cursor: pointer;
  }
  .part1 .li:hover .iconfont {
    transform: scale(1.2,1.2);
  }
  .part1 .li:hover .h {
    color: #007dff;
  }
  .part1 .iconfont {
    font-size: 42px;
    margin-right: 16px;
    background-image: -webkit-linear-gradient(#00e3ff, #007dff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all .4s ease;
    transform: scale(1,1);

  }
  .part1 .li .h {
    font-size: 24px;

  }
  .part1 .li .dec {
    font-size: 14px;
    opacity: .7;
    margin-top: 10px;
  }
  .part1 .li:before{
    content: '';
    position: absolute;
    width: 1px;
    top: 50%;
    right: 20%;
    transform: translateY(-50%);
    height: 40%;
    background: #e2e2e2;
  }
  .part1 .li:last-child:before{
    display: none;
  }
  .part2 {
    padding: 80px 0;
  }
  .p-title{
    font-size: 26px;
    margin: 80px 0 40px 0;
  }
  .p-title span{
    font-size: 26px;
    margin: 0 20px;
  }
  .part2 .h{
    font-size: 26px;

  }
  .part2 .li .iconfont {
    font-size: 36px;
    margin-right: 20px;
  }
  .part2 .li {
    padding: 52px;
    width: 50%;
    border: 1px solid #e2e2e2;
    position: relative;
    cursor: pointer;
  }
  .part2 .li:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    /*transform: translateX(-50%);*/
    background-color: #007dff;
    background-image: linear-gradient(to right,#00e3ff, #007dff);
    transition: all .5s ease;
  }
  .part2 .li:hover:before {
    width: 100%
  }
  .part2 .li:before {
    content: '';
    display: block;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    background: linear-gradient(#00e3ff, #007dff);
  }
  .part2 .li:nth-child(1),.part2 .li:nth-child(3) {
    margin-right: -1px;
  }
  .part2 .li:nth-child(3),.part2 .li:nth-child(4) {
    margin-top: -1px;
  }
  .part2 .li:nth-child(3) {
    border-top: 0;
  }
  .part2 .li:nth-child(4) {
    border-left: 0;
  }
  .part2 .dec {
    font-size: 14px;
    opacity: .6;
  }
  .part3 {
    padding: 80px 0;
    width: 100%;
    height: 760px;
    background: url("../../../public/img/index-bg.jpg");

  }

  .part3 .p-title {
    color: #e1e1e1;
  }
  .part3 .li-wrap {
    color: white;
  }
  .part3 .li .iconfont {
    font-size: 82px;
  }
  .part3 .li .iconfont {
    font-size: 82px;
  }
  .part3 .li {
    position: relative;
    width: 25%;
    text-align: center;
    border: 1px solid #3a3a3a;
    margin-right: -1px;
    margin-bottom: -1px;
    box-sizing: border-box;
    padding: 60px 0;
    height: 255px;
    overflow: hidden;
  }

  .part3 .li > div {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .5s ease;
  }
  .part3 .li .hover-show {
    top: 100%;
    transition: all .5s ease;
    color: #f6f6f6;
    line-height: 28px;
    text-align: justify;
    padding: 20px;
    font-size: 14px;
    background: rgba(255,255,255,.1);

  }
  .part3 .li .hover-hide {
    top: 0;
    transition: all .5s ease;
  }
  .part3 .li:hover .hover-show {
    top: 0;
    transition: all .5s ease;
  }
  .part3 .li:hover > .hover-hide {
    top: -100%;
    transition: all .5s ease;

  }
  .part3 .li-tit {
    font-size: 18px;
    margin-top: 20px;
  }
  .part4 {
    padding: 80px 0;
    width: 100%;
    height: 800px;
    background: url("../../../public/img/hangye-bg.jpg") ;
    background-size: 1920px;
    background-position: center top;
    /*margin-bottom: 12vh;*/
  }
  .part4 .icon-svg {
    animation: svg-ani 3s infinite;
  }
  .part5 {
    padding: 80px 0;
    background: #ffffff;
  }
  .part5 .li {
    width: 25%;
    padding-bottom: 30px;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 0 16px rgba(0,0,0,.1);
    transition: all .4s ease;
    transform: scale(1,1);
  }
  .part5 .li:hover {
    transform: scale(1.1,1.1);
    box-shadow: 0 0 26px rgba(0,0,0,.2);
  }
  .part5 .li .li-tit{
    font-size: 28px;
    height: 52px;
    position: relative;
    margin-top: 20px;
  }
  .part5 .li .li-tit:before{
    position: absolute;
    content: '';
    width: 70px;
    bottom: 0;
    height: 3px;
    background: #0ea2fd;
    left: 50%;
    transform: translateX(-50%);
  }
  .part5 .li .dec {
    min-height: 200px;
    padding-top: 20px;
    opacity: .8;

  }
  .part5 .li p{
    font-size: 16px;
  }
  .footer {
    margin-top: 0;
    margin-bottom: 0;
  }
  @keyframes svg-ani {
    0% {
      transform: scale(1,1);
      opacity: 1;
    }
    50% {
      transform: scale(.5,.5);
      opacity: .5;
    }
    100% {
      transform: scale(1,1);
      opacity: 1;
    }
  }
  .part4 .li {
    position: absolute;
    z-index: 99;
  }
  .part4 .li .say-con{
    position: absolute;
    left: 50%;
    width: 16vw;
    transform: translate(-50%,20px);
    bottom: 72px;
    border: 1px solid #f8f8f8;
    padding: 20px;
    background: rgba(255,255,255,.8);
    border-bottom :5px solid #0066ff;
    opacity: 0;
    transition: all .8s ease;

  }
  .part4 .li .say-con.show{
    transform:translate(-50%, 0);
    opacity: 1;
    transition: all .4s ease;
  }
  .part4 .li:nth-child(2) .say-con{
    position: absolute;
    border-bottom :5px solid #ff9c00;
  }
  .part4 .li:nth-child(3) .say-con{
    position: absolute;
    border-bottom :5px solid #00d4f7;
  }
  .part4 .li:nth-child(4) .say-con{
    position: absolute;
    border-bottom :5px solid #f70067;
  }
  .part4 .li .say-con::before {
    content: '';
    position: absolute;
    bottom: -23px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #0066ff;
  }
  .part4 .li:nth-child(2) .say-con::before {
    border-top-color: #ff9c00;
  }
  .part4 .li:nth-child(3) .say-con::before {
    border-top-color: #00d4f7;
  }
  .part4 .li:nth-child(4) .say-con::before {
    border-top-color: #f70067;
  }
  .part4 .li:nth-child(1) {
    left: 50%;
    top: 160px;
  }
  .part4 .li:nth-child(2) {
    left: 10%;
    top: 350px;
  }
  .part4 .li:nth-child(3) {
    left: 38%;
    top: 530px;
  }
  .part4 .li:nth-child(4) {
    left: 70%;
    top: 500px;
  }
  .zxw-nav {
    /*background: linear-gradient(90deg, #35bce2, #409eff);*/
    background: white;
    line-height: 2.4rem;
    width: 100%;
    outline: none;
  }

  .zxw-nav .wrap {
    width: 1300px;
    margin: 0 auto;
  }

  .zxw-nav li {
    width: 7rem;
    color: #404040;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
  }

  .zxw-nav li:hover,
  .zxw-nav li.on {
    background: #69c2ff;
    font-size: 15px;
    color: white;
  }
  .pop-aside-wrap {
    height: 415px;
    /*padding-right: 50px;*/
    position: relative;
    overflow: hidden;
    /*line-height: 32px;*/
    transition: all .3s;
  }
  .pop-aside-wrap-expand {
    height: calc(100vh - 190px - 24px);
    /*overflow-y: auto;*/
    padding-bottom: 24px;
  }

  .el-popper[x-placement^="right"] {
    margin-left: 0;
    border-radius: 0;
    padding-left: 20px;
  }

  .menu-box .tit {
    color: white;
    width: 200px;
    line-height: 2.4rem;
    padding-left: 1.4rem;
    background: #409eff;
    border-radius: 10px 10px 0 0;
    /*margin-top: -2.4rem;*/
    cursor: pointer;
  }

  .menu-box {
    position: absolute;
    top: 50px;
    z-index: 1999;
    background: rgba(255, 255, 255, .95);
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
  }

  .menu-box .menu {
    width: 200px;
    height: 408px;
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: top left;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }

  .menu-box .menu.hide {
    height: 0;
    width: 0;
    transform: scale(0, 0);
    opacity: 0;
  }

  .menu-box .menu > div {
    /*min-height: 452px;*/
  }

  .menu .tab .iconfont {
    font-size: 20px;
    width: 26px;
    margin-right: 6px;
    font-weight: normal;
  }

  .menu .tab-box {
    padding-top: 10px;
    padding-left: 0;
  }

  .menu .tab-box .tab {
    height: 48px;
    cursor: pointer;
    font-size: 16px;
    color: #6d6d6d;
    width: 100%;
    padding: 0 5px 0 13px;
    position: relative;
  }

  .menu .tab-box .tab:hover,
  .menu .tab-box .tab.on {
    /*color: #409eff;*/
    background: #ffffff;
  }

  .menu .tab-box .tab:hover:before,
  .menu .tab-box .tab.on:before {
    /*color: #409eff;*/
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 42px;
    width: 100%;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
    /*background: #ffffff;*/
    z-index: -1;
  }

  .menu-pop {
    height: 452px;
    position: absolute;
    left: 199px;
    top: 0;
    width: 200px;
    background: #fff;
    /*min-width: 200px !important;*/
    /*max-width: 750px !important;*/
    border-radius: 4px;
    border: 1px solid #ebeef5;
    padding: 12px;
    z-index: -1;
    color: #606266;
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    word-break: break-all;
    transition: width .3s ease-in-out;

  }
  .menu-pop.large-type {
    width: 880px;
    transition: width .3s ease-in-out;

  }

  .menu-pop-expand {
    height: calc(100vh - 190px);
    padding: 12px 12px 42px 12px;
  }

  .box-shadow {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .pop-aside-wrap .pop-title {
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    cursor: pointer;
  }
  .pop-aside-wrap .pop-title:hover {
    color: #409eff;
  }

  .pop-aside-wrap .second-list {
    cursor: pointer;
  }

  .pop-aside-wrap .second-wrap {
    /*flex-wrap: nowrap;*/
  }

  .pop-aside-wrap .second-list:hover {
    color: #409eff;
  }

  .menu .icon-jiantou-you {
    font-size: 12px !important;
  }

  /*服务贴边栏样式*/
  .menu-box.fixed-left {
    position: fixed;
    left: 40px;
    top: 140px;
    border-radius: 0 10px 10px 10px;
  }

  .menu-box.fixed-left .el-icon-menu {
    margin-right: 6px;
    margin-bottom: 6px;
    margin-top: 10px;
  }

  .menu-box.fixed-left .tit {
    position: absolute;
    left: -40px;
    width: 40px;
    height: 200px;
    padding: 8px;
    flex-direction: column;
    line-height: 1.2rem;
    border-radius: 0;
  }

  .expand-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 42px;
    line-height: 42px;
    background: #fff;
    opacity: 0.9;
    text-align: center;
    cursor: pointer;
    color: #409eff;
  }
  .expand-btn:hover {
    opacity: 0.8;
  }

  .suspension {
    position: fixed;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    top: 140px;
    right: 100px;
    z-index: 2000;
    cursor: pointer;
    text-align: center;
    line-height: 75px;
    font-size: 16px;
    color: #409eff;
    background: rgba(0, 0, 0, 0.2);
  }
  /*需求弹框覆盖样式*/
  .el-dialog/deep/.el-dialog__footer{
    padding-top: 0;
  }
  .el-dialog/deep/.el-dialog__header{
    padding-bottom: 0;
  }

  .el-form /deep/ .el-button {
    width: 150px;
    transform: translateX(-40px);
  }
  .icon-svg-menu {
    width: 24px;
    margin-right: 5px;
    text-align: center;
    font-size: 16px;
    color: inherit !important;
    transition: inline-block .3s, left .3s, width .3s, margin-left .3s, font-size .3s;
  }
  .zxw-nav >>> .el-scrollbar__wrap{
    overflow-x: hidden !important;
  }
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  .top-banner-wrapper {
    width: 100%;
    height: 600px;
    background-color: #5266FF;
    /*display: flex;*/
    /*justify-content: flex-end;*/
  }
  .top-banner-wrapper .content-wrapper {
    position: absolute;
    left: 15%;
    top: 30%;
    width: 40%;
    height:600px;
    color: white;
    line-height: 3.8rem;
    font-size: 2.6rem;
    font-weight: 800;
    text-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  }
  .top-banner-wrapper .logo {
    position: absolute;
    right: 150px;
    top: 100px;
    width: 400px;
    height: 600px;
    background-image: url("../../assets/img/serve/persons.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
.banner-background {
  background-image: url("../../assets/img/serve/group6.png");
  background-size: contain;
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-background .center {
  /*left: 144px;*/
  /*top: 308px;*/
  /*display: inline;*/
  display: flex;
  width: 1152px;
  height: 554px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 12px 20px rgba(216, 224, 230, 1);
}
.description {
  position: relative;
  left: 56px;
  top: 56px;
  width: 321px;
  height: 36px;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  line-height: 46px;
  font-weight: 500;
}
  .description-detail {
    text-indent: 28px;
    position: relative;
    left: 56px;
    top: 156px;
    width: 439px;
    height: 96px;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
  }
.banner-background .left {
  /*display: inline;*/
  left: 0px;
  top: 0px;
  width: 576px;
  height: 554px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
  background-image: url("../../assets/img/serve/city.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 554px;
}
.banner-background .right {
  /*left: 144px;*/
  /*top: 308px;*/
  width: 576px;
  height: 554px;
  background: rgba(255, 255, 255, 1);
  /*box-shadow: 0px 12px 20px rgba(216, 224, 230, 1);*/
}
.banner-container .txt {
  line-height: 2vw;
  text-align: justify;
}
  .item-box1 {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 181px;
  }
  .content-row {
    height: 450px;
    /*background-color: #F4F6FC;*/
    display: flex;
    justify-content: center;
    align-content: center;
    /*background-image: url("../../assets/img/serve/group6.png");*/
  }
  .sub-desc {
    margin-top: 1rem;
    font-size: 1rem;
    color: #F4F6FC;
    font-weight: normal;
    line-height: 1.8rem;
  }
  .row-img-wrapper {
    /*background-color: #409EFF;*/
    width: 40%;
    height: 80%;
  }
  .img-container {
    /*height: 500px;*/
    /*margin-left: 10%;*/
    display: flex;
    justify-content: center;
    justify-items: center;
  }
  .desc-container {
    /*text-align: center;*/
  }
  .desc-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .desc-content {
    line-height: 1.8rem;
    text-indent: 2rem;
  }
  .row-desc-wrapper {
    /*background-color: red;*/
    width: 40%;
    height: 100%;
  }
  .content-row2 {
    height: 450px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .item-logo {
    background: url("../../assets/img/serve/persons.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 180px;
    height: 100%;
    color: #409EFF;
  }
  .item-desc {
    width: 336px;
    left: 10px;
    top: 20px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
  }
  .item-detail {
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    color: #909399;
  }
  .count-wrapper {
    background-color: #F2F6FC;
    height: 150px;
    width: 100%;
  }
</style>
